import {MDBBtn, MDBInput, MDBSpinner, MDBTextArea} from "mdb-react-ui-kit";
import ax from "./service/axios";
import {useContext, useState} from "react";
import {AppContext} from "../App";

function NewItemForm() {
    const appCtx = useContext(AppContext);
    const [itemName, setItemName] = useState(null)
    const [unitPrice, setUnitPrice] = useState(null)
    const [discountedUnitPrice, setDiscountedUnitPrice] = useState(null)
    const [stock, setStock] = useState(null)
    const [itemCategory, setItemCategory] = useState(null)
    const [itemDesc, setItemDesc] = useState(null)
    const [unitType, setUnitType] = useState(null);
    const [saveBtnIsActive, setSaveBtnIsActive] = useState(true);

    const config = {
        headers: {
            customerId: "SASTA_BAZAAR",
            user: appCtx.user
        }
    }

    const toggleButton = () => {
        setSaveBtnIsActive(false)
    }

    function saveNewItemDetails() {
        const res = ax.post("/products/add", {
            productName: itemName,
            unitPrice: unitPrice,
            discountedUnitPrice: discountedUnitPrice,
            productCategory: itemCategory,
            unitType: unitType,
            productDescription: itemDesc
        }, config);
        res
            .then(e => { console.log("Saved"); setSaveBtnIsActive(true); alert("Data Saved.."); appCtx.setNewItemUploaded(!appCtx.newItemUploaded) })
            .catch(e => { console.log("Err"); toggleButton();})
    }
    return (
        <div className="p-4 flex flex-col gap-2 font-poppins bg-yellow-50 rounded shadow flex-1 mr-2 border-2 border-gray-600">
            <b>Product Add Form / নতুন আইটেম যোগ করুন</b>
            <br/><br/>
            <MDBInput label='Item Name / আইটেম-এর নাম' id='typeText' type='text'
                wrapperStyle={{ color: 'red', background: '#99FFCC44' }} onChange={e => setItemName(e.target.value)} />
            <MDBInput label='MRP / অরিজিনাল দাম' id='typeNumber' type='number' wrapperStyle={{ background: '#99FFCC44' }}
                 onChange={e => setUnitPrice(e.target.value)} />
            <MDBInput label='Sasta bazaar MRP / সস্তা বাজার দাম' id='typeNumber' type='number' wrapperStyle={{ background: '#99FFCC44' }}
                      onChange={e => setDiscountedUnitPrice(e.target.value)} />
            {/*<MDBInput label='Stock' id='typeNumber' type='number' wrapperStyle={{ background: '#99FFCC44' }}*/}
            {/*          onChange={e => setStock(e.target.value)} />*/}
            <MDBTextArea label='Description / আইটেম-এর আর কিছু ডিটেলস' id='textAreaExample' rows={4} wrapperStyle={{ background: '#99FFCC44' }}
                onChange={e => setItemDesc(e.target.value)} />
            <br/>
            <div className="flex flex-col justify-center items-center">
                <div className="flex flex-row justify-center items-center">
                    <MDBBtn size='lg' className="me-2" color="success" disabled={!saveBtnIsActive} onClick={e => { saveNewItemDetails(); toggleButton(e); }}>SAVE</MDBBtn>
                    <MDBBtn size='lg' className="me-2" color="warning" onClick={e => { }}>RESET</MDBBtn>
                </div>

                {!saveBtnIsActive &&
                    <div className={"p-2 flex flex-col justify-center items-center content-center"}>
                        <MDBSpinner role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                        <div>Saving.. Wait..</div>
                    </div>
                        }
                    </div>
                    </div>

    )
}

export default NewItemForm;

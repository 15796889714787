import {MDBBtn} from "mdb-react-ui-kit";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../App";
import LogoAndCustomerName from "./LogoAndCustomerName";
import {clearLocalStorage} from "./AuthenticationComponent";
import {Navigate} from "react-router-dom";

function GenericTitle() {
    const appCtx = useContext(AppContext);
    const [date, setDate] = useState("")
    useEffect(() => {
        const interval = setInterval(() => {
            setDate(new Date().toLocaleDateString());
        }, 1000);
        return () => clearInterval(interval);
    }, [appCtx.user])

    return (
        <div className={"p-4 text-5xl font-bold text-gray-800 flex flex-row w-screen"}>
            <LogoAndCustomerName/>
            <div className="flex flex-row items-center flex-1 gap-8">
                <div className="text-2xl flex-1 text-right text-red-800">{date}</div>
                {appCtx.user &&
                    <div className="flex flex-col justify-center items-center content-center ">
                        <div className="text-xl text-right text-blue-900">Welcome <b>{appCtx.user}</b></div>
                        <div className="items-center justify-center content-center">
                            <MDBBtn className="me-1" color="danger" onClick={e => { appCtx.setUser(null); clearLocalStorage(); return <Navigate to={"/"} replace="true" />}}>Logout</MDBBtn>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default GenericTitle;

import {useContext, useEffect, useRef, useState} from "react";
import {Typeahead} from 'react-bootstrap-typeahead';
import {Form} from 'react-bootstrap';
import ax from "./service/axios";
import {
    MDBBtn,
    MDBIcon,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle, MDBSpinner,
} from 'mdb-react-ui-kit';
import {AppContext} from "../App";
import ReactToPrint, {useReactToPrint} from "react-to-print";
import {Navigate} from "react-router-dom";
import {getItemFromStorage} from "./AuthenticationComponent";
import {PrintComponent} from "./PrintComponent";
import Html2Pdf from "js-html2pdf/src";
import {Tooltip} from "@mui/material";


function Billing() {
    const billing = useContext(AppContext);
    const [reloadForRemove, setReloadForRemove] = useState(false);
    const [itemsLoaded, setItemsLoaded] = useState(false);
    const [itemPriceAfterDiscount, setItemPriceAfterDiscount] = useState(0);
    const [customerMobileNumber, setCustomerMobileNumber] = useState("");
    const [saveBillAPIStatus, setSaveBillAPIStatus] = useState("");
    //const [saveBillBtnIsActive, setSaveBillBtnIsActive] = useState(true);
    const componentRef = useRef();
    const tokenComponentRef = useRef();
    const tokenDeleteComponentRef = useRef();
    const tokenSingleItemComponentRef = useRef();
    const pageStyle = "@page { size: 58mm 170mm }";

    const toggleShow = () => billing.setBasicModal(!billing.basicModal);

    function getBill() {
        var total = 0.0, totalWithDiscount = 0.0, dis = 0.0, finalAmount = 0.0, discountedPrice = 0.0, totalBeforeDiscount = 0.0;
        var fixedSGSTPer = 2.5;
        var fixedCGSTPer = 2.5;
        billing.itemsSelectedDetails.map(item => {
            total += (item.totalPrice)
            // totalWithDiscount += Math.ceil(item.totalPrice * (1 - (item.discount / 100.0)))
            discountedPrice += parseInt(item.itemPriceAfterDiscount);
        })

        const billDetails = {
            "finalAmount": total,
            "totalBeforeDiscount": total,
            "totalWithDiscount": discountedPrice,
        };
        return billDetails;
    }

    useEffect(() => {
        if (getItemFromStorage("userName")) {
            billing.setUser(getItemFromStorage("userName"));
        }
        if (getItemFromStorage("restaurantId")) {
            billing.setRestaurantId(getItemFromStorage("restaurantId"));
        }
    },);

    useEffect(() => {
        billing.setBillData(getBill());
    }, [billing.itemsSelectedDetails]);

    function getWastages() {
        var wastagesForCurrentSeat = []
        billing.wastage.map(wastage => {
            wastagesForCurrentSeat.push(wastage);
        })
    }

    function removeItem(idx) {
        billing.itemsSelectedDetails.map((item, index) => {
            if (index === idx) {
                billing.itemsSelectedDetails.splice(index, 1);
                billing.setBillData(getBill());
                setReloadForRemove(!reloadForRemove)
            }
        })
    }

    const renderMenuItemChildren = (option, props) => (
        <div>
            <span>{option.productName} - [MRP {option.unitPrice}/-]</span>
        </div>
    );
    const [data, setData] = useState([]);

    /** API CAllS **/
    const config = {
        headers: {
            customerId: 'SASTA_BAZAAR',
            user: billing.user
        }
    }
    useEffect(() => {
        async function loadItems() {
            try {
                const response = await ax.get('/products/fetch/all', config);
                setData(response.data);
                setItemsLoaded(true);
            } catch (error) {
                console.log('Error in fetching data from backend', error);
            }
        }
        loadItems();
    }, [billing.newItemUploaded]);

    function saveData() {
        //setSaveBillBtnIsActive(false);
        var bill = getBill();
        var wastage = getWastages();
        var items = []
        billing.itemsSelectedDetails.map(item => {
            items.push(item);
        });
        ax.post("/billing/bill/create", {
            billItemList: items,
            gstApplied: billing.gst,
            finalAmount: bill.totalWithDiscount,
            totalBeforeDiscount: bill.totalBeforeDiscount,
            // cgstAmount: bill.cgstAmount,
            // sgstAmount: bill.sgstAmount,
            customerId: billing.restaurantId,
            customerMobileNumber: customerMobileNumber,
            wastages: wastage
        }, config)
            .then(e => { console.log("Saved"); alert("Bill Saved.."); clearData(); })
            .catch(e => { console.log("Err"); })
    }

    /** Ends Here */

    function clearData() {
        for (let index = 0; index < billing.itemsSelectedDetails.length; index++) {
            console.log(JSON.stringify(index));
            billing.itemsSelectedDetails.splice(index, 1);
            index = -1;
        }
        setCustomerMobileNumber("")
        setReloadForRemove(!reloadForRemove);
    }

    function clearItem(index) {
        alert(index);
        billing.itemsSelectedDetails.splice(index, 1);
        console.log("cleared")
        billing.setBillData(getBill());
        // billing.setSaveMessage(null);
        //billing.setGst({});
        //billing.setDiscount({});
        //console.log(JSON.stringify(billing.itemsSelectedDetails));
        setReloadForRemove(!reloadForRemove);
    }

    function checkAtLeastOneItem() {
        return billing.itemsSelectedDetails.length > 0
    }

    if (!billing.user) {
        return <Navigate to={"/"} replace="true" />
    }
    else {
        return (
            <div className="pl-6 pr-6 flex flex-col">
                <div className="flex mt-2">
                    <div
                        className="h-20 pb-2 pl-2 pr-2 w-96 rounded bg-gray-400 shadow border-2 border-blue-200 flex-1">
                        <Form.Group>
                            <Form.Label><b>Search Item</b></Form.Label>
                            <Typeahead
                                id="basic-typeahead-single"
                                labelKey="productName"
                                onChange={(e) => {
                                    billing.setItemSelected(e[0]);
                                    billing.setBasicModal(true);
                                }}
                                options={data}
                                placeholder=" Type Here"
                                inputProps={{className: "bg-white border border-red-400 rounded w-full h-8"}}
                                selected={billing.singleSelection}
                                renderMenuItemChildren={renderMenuItemChildren}
                                disabled={!itemsLoaded}
                            />
                        </Form.Group>
                        {!itemsLoaded &&
                        <div className={"p-8 flex flex-col justify-center items-center content-center"}>
                            <MDBSpinner role='status'>
                                <span className='visually-hidden'>Loading...</span>
                            </MDBSpinner>
                            <div>Loading Products..</div>
                        </div>
                        }
                    </div>
                    <div className={"h-full w-1 m-1 bg-black"}></div>
                    <div className="flex-1">
                        <div className="flex justify-center mt-8">
                            {/*<form onSubmit={e => saveData()}>*/}
                            <div className={"flex flex-col"}>
                            <div className={"flex flex-row justify-center items-center gap-2 p-2"}>
                                    <div>Customer Mobile Number:</div>
                                    <div><MDBInput id={"cmobno"} className={"rounded bg-white"}
                                                   label={"Customer Mobile Number"}
                                                   onChange={e => setCustomerMobileNumber(e.target.value)}
                                                   required/></div>
                                </div>
                                <table>
                                    <thead className="mb-2">
                                    <tr className="bg-red-100" align="center">
                                        <th className="p-2 rounded-l border-2 border-blue-gray-700">Particulars</th>
                                        <th className="p-2 border-2 border-blue-gray-700">Qty</th>
                                        <th className="p-2 border-2 border-blue-gray-700">Unit Price</th>
                                        <th className="p-2 rounded-r border-2 border-blue-gray-700">Total Unit
                                            Price
                                        </th>
                                        {/*<th className="p-2 rounded-r border-2 border-blue-gray-700">Discount</th>*/}
                                        <th className="p-2 rounded-r border-2 border-blue-gray-700">Total
                                            Discounted
                                            Price
                                        </th>
                                    </tr>
                                    </thead>
                                    {checkAtLeastOneItem() && billing.itemsSelectedDetails.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className="text-center p-2 border-2 border-blue-gray-700 bg-blue-100">{item.productName}</td>
                                                <td className="text-center p-2 border-2 border-blue-gray-700 bg-green-50">{item.quantity}</td>
                                                <td className="text-center p-2 border-2 border-blue-gray-700 bg-red-50">{item.unitPrice}/-</td>
                                                <td className="text-center p-2 border-2 border-blue-gray-700 bg-orange-100 font-bold">{item.totalPrice}/-</td>
                                                {/*<td className="text-center p-2 border-2 border-blue-gray-700 bg-orange-100 font-bold">{item.discount}%</td>*/}
                                                <td className="text-center p-2 border-2 border-blue-gray-700 bg-orange-100 font-bold">{item.itemPriceAfterDiscount}/-</td>
                                                <td className="text-center p-2">
                                                    <MDBBtn size='lg' floating style={{backgroundColor: 'red'}}
                                                            onClick={e => clearItem(index)}>
                                                        <MDBIcon fas icon="trash"/>
                                                    </MDBBtn>

                                                    {/*<ReactToPrint*/}
                                                    {/*    trigger={(e) => {*/}
                                                    {/*        return <MDBBtn size='lg' floating*/}
                                                    {/*                       style={{backgroundColor: 'green'}} href='#'>*/}
                                                    {/*            <MDBIcon fas icon="print"/>*/}
                                                    {/*        </MDBBtn>*/}
                                                    {/*    }}*/}
                                                    {/*    pageStyle={pageStyle}*/}
                                                    {/*    onBeforeGetContent={e => {*/}
                                                    {/*        billing.setItemNewAdded(item)*/}
                                                    {/*    }}*/}
                                                    {/*    content={() => tokenSingleItemComponentRef.current}/>*/}
                                                </td>
                                            </tr>)
                                    })}
                                    {/*{checkAtLeastOneItem() && billing.gst && <tr>*/}
                                    {/*    <td colSpan={3} className="text-center p-2 border-gray-900 text-l font-bold border bg-yellow-200">SGST @2.5%</td>*/}
                                    {/*    <td className="text-center p-2 font-bold bg-orange-100 border-gray-900 border">(+) {Math.round((getBill().sgstAmount) * 100) / 100}/-</td>*/}
                                    {/*</tr>}*/}
                                    {/*{checkAtLeastOneItem() && billing.gst && <tr>*/}
                                    {/*    <td colSpan={3} className="text-center p-2 border-gray-900 text-l font-bold border bg-yellow-200">CGST @2.5%</td>*/}
                                    {/*    <td className="text-center p-2 font-bold bg-orange-100 border-gray-900 border">(+) {Math.round((getBill().cgstAmount) * 100) / 100}/-</td>*/}
                                    {/*</tr>}*/}
                                    {checkAtLeastOneItem() &&
                                        <tr>
                                            <td colSpan={3}
                                                className="text-center p-2 text-l font-bold  border-gray-900 border-2 bg-red-200">Total
                                                Bill (Rounded)
                                            </td>
                                            <td className="text-center p-2 text-l bg-blue-gray-100 font-bold border-gray-900 border-2">{getBill().totalBeforeDiscount}/-</td>
                                            <td className="text-center p-2 text-l bg-blue-gray-100 font-bold border-gray-900 border-2">{getBill().totalWithDiscount}/-</td>
                                        </tr>
                                    }
                                    <tr><br/></tr>
                                    <tr><br/></tr>

                                    {checkAtLeastOneItem() &&
                                        <tr align="center">
                                            <td colSpan={6}>
                                                <MDBBtn size='lg' className="me-2" color="success"
                                                        onClick={(e) => saveData()}>
                                                    Save Bill
                                                </MDBBtn>
                                                <ReactToPrint
                                                    trigger={(e) => {
                                                        return <MDBBtn size='lg' className="me-2" color="info">
                                                            Print Bill
                                                        </MDBBtn>
                                                    }}
                                                    pageStyle={pageStyle}
                                                    content={() => componentRef.current}/>
                                                {/*<ReactToPrint*/}
                                                {/*    trigger={(e) => {*/}
                                                {/*        return <MDBBtn size='lg' className="me-2" color="warning">*/}
                                                {/*            Print Token*/}
                                                {/*        </MDBBtn>*/}
                                                {/*    }}*/}

                                                {/*    pageStyle={pageStyle}*/}
                                                {/*    content={() => tokenComponentRef.current} />*/}
                                            </td>
                                        </tr>
                                    }
                                    {<tr>
                                        <td><br/>
                                        </td>
                                    </tr>}
                                    {billing.saveMessage && checkAtLeastOneItem() &&
                                        <tr align="center"
                                            className="bg-yellow-50 p-8 rounded w-full font-bold">
                                            <td colSpan={4}>{billing.saveMessage}</td>
                                        </tr>
                                    }
                                </table>
                                {/* Hidden Print Component Body */}
                                <div style={{display: "none"}}>
                                    <PrintComponent ref={componentRef}/>
                                </div>
                                {/*<div style={{ display: "none" }}>*/}
                                {/*    <PrintTokenComponent ref={tokenComponentRef} props={{ "bill": getBill() }} />*/}
                                {/*</div>*/}

                                {/* Hidden Print Component Body Ends */}
                            </div>
                            {/*</form>*/}
                        </div>
                    </div>


                    <MDBModal show={billing.basicModal} setShow={billing.setBasicModal} tabIndex='-1'>
                        <MDBModalDialog>
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>Add Item To Bill</MDBModalTitle>
                                    <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <div className={"border-2 border-amber-900 p-2"}>Item Name / আইটেম-এর নাম : <b><span
                                        className={"text-black"}>{billing.itemSelected["productName"]}</span></b> </div><br/>
                                    <div className={"border-2 border-amber-900 p-2"}>
                                        Quantity:&nbsp;
                                        <select className={"border-2 border-black rounded bg-red-50 p-1 font-bold text-black"}
                                            onChange={e => billing.setQuantity(e.target.value)}
                                            value={billing.quantity} defaultValue={"default"}>
                                        <option id={"default"}>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                        <option>10</option>
                                        </select></div><br/>
                                    <div className={"border-2 border-amber-900 p-2"}>MRP / অরিজিনাল দাম:
                                        <b>&nbsp;&nbsp;
                                        <span className={"text-black"}>{billing.itemSelected["unitPrice"]}/-</span></b>
                                        </div>
                                    <br/>
                                    <div className={"border-2 border-amber-900 p-2"}>Sasta Bazaar MRP / সস্তা বাজার দাম: <b><span
                                        className={"text-black"}>{billing.itemSelected["discountedUnitPrice"]}/-</span> &nbsp;&nbsp;&nbsp;
                                    </b> </div><br/>
                                    <div className={"border-2 border-amber-900 p-2"}>
                                        Discounted Price / ডিসকাউন্ট দাম:
                                    <div className={"flex flex-col gap-2"}>
                                        <MDBInput className={"border-2 border-amber-900 rounded text-red"}
                                                  onChange={e => setItemPriceAfterDiscount(e.target.value)}
                                                  defaultValue={"0"}/>
                                        <span className={"flex-1"}>Discount / ডিসকাউন্ট শতাংশ:
                                            <span className={"text-red-400 font-bold"}>&nbsp;&nbsp;{Math.ceil(((billing.itemSelected["unitPrice"] - itemPriceAfterDiscount) / billing.itemSelected["unitPrice"]) * 100)}% 🙂</span>
                                        </span>
                                    </div>
                                    </div>
                                    <br/>
                                    {/*Discounted Price: <b>{Math.ceil((Number(billing.quantity) * Number(billing.itemSelected["unitPrice"])) * (1 - (Number(billing.discount) / 100.0)))}</b>*/}
                                </MDBModalBody>
                                <MDBModalFooter>
                                    <MDBBtn color='secondary' onClick={toggleShow}>
                                        Close
                                    </MDBBtn>
                                    <MDBBtn onClick={(e) => {
                                        toggleShow(); billing.setItemSelectedDetails(prev => ([
                                            ...prev, {
                                                "productId": billing.itemSelected["id"],
                                                "productName": billing.itemSelected["productName"],
                                                "quantity": billing.quantity,
                                                "discount": billing.discount,
                                                "unitPrice": billing.itemSelected["unitPrice"],
                                                "discountedUnitPrice": billing.itemSelected["discountedUnitPrice"],
                                                "itemPriceAfterDiscount": itemPriceAfterDiscount,
                                                "totalPrice": (Number(billing.quantity) * Number(billing.itemSelected["unitPrice"])),
                                                "gst": billing.gst
                                            }
                                        ]));
                                    }}>ADD TO BILL</MDBBtn>
                                </MDBModalFooter>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>
                </div>
            </div >
        )
    }
}

export default Billing

import {BrowserRouter as Router} from "react-router-dom";
import RoutingSetup from "./components/RoutingSetup";
import LinkSetup from "./components/LinkSetup";
import {createContext, useEffect, useState} from "react";
import GenericTitle from "./components/GenericTitle";

export const AppContext = createContext();
function App() {
    const [singleSelection, setSingleSelection] = useState([]);
    const [itemsSelectedDetails, setItemSelectedDetails] = useState([])
    const [itemSelected, setItemSelected] = useState({});
    const [gst, setGst] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [area, setArea] = useState(null);
    const [userType, setUserType] = useState(null);
    const [plan, setPlan] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [quantity, setQuantity] = useState(1)
    const [basicModal, setBasicModal] = useState(false);
    const [selectedSeatingNo, setSelectedSeatingNo] = useState(null);
    const [numberOfCabins, setNumberOfCabins] = useState(10);
    const [numberOfGroups, setNumberOfGroups] = useState(10);
    const [numberOfTanks, setNumberOfTanks] = useState(2);
    const [numberOfRooms, setNumberOfRooms] = useState(3);
    const [numberOfFans, setNumberOfFans] = useState(2);
    const [user, setUser] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [customerAddr, setCustomerAddr] = useState(null);
    const [billData, setBillData] = useState({});
    const [txnDetails, setTxnDetails] = useState(null);
    const [newItemUploaded, setNewItemUploaded] = useState(false);
    const [wastage, setWastage] = useState([]);
    const [isDepositModalActive, setIsDepositModalActive] = useState(false);
    const [clientId, setClientId] = useState(null);

    const store = {
        singleSelection, setSingleSelection, itemsSelectedDetails, setItemSelectedDetails, plan, setPlan,
        itemSelected, setItemSelected, gst, setGst, discount, setDiscount, quantity, setQuantity, area, setArea,
        basicModal, setBasicModal, selectedSeatingNo, setSelectedSeatingNo, txnDetails, setTxnDetails, customerAddr, setCustomerAddr,
        numberOfCabins, setNumberOfCabins, numberOfGroups, setNumberOfGroups, numberOfTanks, setNumberOfTanks, customerId, setCustomerId,
        numberOfRooms, setNumberOfRooms, numberOfFans, setNumberOfFans, user, setUser, userType, setUserType, billData, setBillData, customerName, setCustomerName,
        clientId, setClientId, newItemUploaded, setNewItemUploaded, wastage, setWastage, isDepositModalActive, setIsDepositModalActive
    }

    // Alerts on page refresh
    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);
    // Alert ends here

    return (
        <AppContext.Provider value={store}>
            {/* <LoadDefault /> */}
            <div className="h-screen flex flex-col relative bg-gray-200">
                <Router className="flex-grow">
                    <GenericTitle />
                    <LinkSetup />
                    <RoutingSetup />
                </Router>
                {/* <Footer /> */}
            </div>
        </AppContext.Provider>
    );
}

export default App;

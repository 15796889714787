import {MDBBtn, MDBInput, MDBTable, MDBTableBody} from "mdb-react-ui-kit";
import {useContext, useState} from "react";
import ax from "./service/axios";
import {setItemInStorage} from "./AuthenticationComponent";
import {AppContext} from "../App";

export default function LoginForm() {
    const appCtx = useContext(AppContext);

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [customerId, setCustomerId] = useState("SASTA_BAZAAR")
    const [errMsg, setErrMsg] = useState("");

    function submitForm() {
        ax.post("/user/fetch", {
            customerId: "SASTA_BAZAAR",
            userName: username,
            password: password
        })
            .then(res => {
                appCtx.setUser(username);
                appCtx.setCustomerId(customerId);
                appCtx.setUserType(res.data.userType);
                setItemInStorage("userName", username);
                setItemInStorage("customerId", customerId);
            })
            .catch(err => { setErrMsg("Some technical error occured. Try after sometime."); console.log(err); })
    }

    return (
        <div className="flex justify-center content-center items-center w-full pt-16">
            <MDBTable bordered className="align-middle shadow" style={{width: "32rem"}}>
                <MDBTableBody>
                    <tr className="bg-gray-100" hidden={true}>
                        <td>CustomerId Id</td>
                        <td><MDBInput label="Enter Customer Id" id="formUnInput" disabled={true}
                                      value={"SASTA_BAZAAR"} type="text"
                                      onChange={e => setCustomerId(e.target.value)}/></td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td>Username</td>
                        <td><MDBInput label="Enter Username" id="formUnInput" type="text"
                                      onChange={e => setUsername(e.target.value)}/></td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td>Password</td>
                        <td><MDBInput label="Enter Password" id="formPwdInput" type="password"
                                      onChange={e => setPassword(e.target.value)}/></td>
                    </tr>
                    <tr align="center" className="bg-red-50">
                        <td colSpan={2}>
                            <MDBBtn color="success" className="me-1" type="submit"
                                    onClick={() => submitForm()}>SUBMIT</MDBBtn>
                            <MDBBtn className="me-1" type="reset" color="warning">RESET</MDBBtn>
                        </td>
                    </tr>
                </MDBTableBody>
            </MDBTable>
            {errMsg}
        </div>
    )
}
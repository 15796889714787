import CarouselSlider from "./CarouselSlider";

export default function GeneralUserPage() {
    return (
        <div>
            <div className="p-4 flex justify-center text-center text-3xl flex-col gap-4 font-poppins">
                <div className="font-bold text-2xl font-poppins">Welcome to Sasta Bazaar</div>
                <div className="font-bold text-2xl">নমস্কার, সস্তা বাজার-এ আপনাকে স্বাগত জানাই</div>
                <CarouselSlider/>
            </div>
        </div>
    )
}
import {forwardRef, useContext, useEffect, useState} from "react";
import {
    CUSTOMER_NAME, CUSTOMER_NAME_PRINT,
    FINALTANDC, NO_CONTENT_SECTION_SEPARATOR,
    SECTION_SEPARATOR,
    THANK_YOU_MSG, THANK_YOU_MSG_BENGALI,
    TODAYS_DATE,
    TODAYS_TIME
} from "./constants/DynamicConstants";
import {AppContext} from "../App";

export const PrintComponent = forwardRef((props, ref) => {
    const appCtx = useContext(AppContext);
    const [billNo, setBillNo] = useState(null);
    var converter = require('number-to-words');

    useEffect(() => {
        setBillNo(Math.floor(Date.now() / 1000));
    })
    function isHavingAnItem() {
        return appCtx.itemsSelectedDetails.length > 0
    }

    return (
        <div ref={ref} className="flex flex-col font-bold font-helvetica">
            <div className="text-lg text-center font-bold">{CUSTOMER_NAME_PRINT}</div>
            <div className="text-center">পঞ্চাননতলা, আলাটি</div>
            <div className="text-center">* 9749083818 *</div>
            <div className="text-center">☀ Free Delivery ☀</div>
            <div className="text-center">{NO_CONTENT_SECTION_SEPARATOR}</div>
            {/*<div className="text-center">{SECTION_SEPARATOR}</div>*/}
            <div className="text-center">BILL | বিল</div>
            <div className="text-center">{NO_CONTENT_SECTION_SEPARATOR}</div>
            <div className="flex flex-row justify-content-center align-items-center" align={"center"}>
                <div className="flex flex-row mt-2 text-xs justify-content-center align-items-center" align={"center"}>
                    <div>{new Date().toLocaleDateString()}</div>
                    &nbsp;
                    <div>{new Date().toLocaleTimeString()}</div>
                </div>
            </div>
            <div className="flex flex-column justify-center mt-2 text-xs">
                {isHavingAnItem() &&
                    <table align="center">
                        <thead className="mb-2">
                        <tr className="text-center" align="center">
                            <th className="text-center p-1">Particulars</th>
                            <th className="text-center p-1">Qty</th>
                            <th className="text-center p-1">MRP</th>
                            <th className="text-center p-1">Total₹</th>
                            <th className="text-center p-1">SBMall₹</th>
                        </tr>
                        </thead>
                        {appCtx.itemsSelectedDetails.map(item => {
                            return (<tr>
                                <td className="text-center">{item.productName}</td>
                                <td className="text-center 2xl">{item.quantity}</td>
                                <td className="text-center">{item.unitPrice}</td>
                                <td className="text-center">{item.totalPrice}</td>
                                <td className="text-center">{item.itemPriceAfterDiscount}</td>
                            </tr>)
                        })}
                        {/*{appCtx.gst &&*/}
                        {/*    <tr>*/}
                        {/*        <td colSpan={3} className="text-center ">SGST</td>*/}
                        {/*        <td className="text-right ">{Math.round((appCtx.billData.sgstAmount) * 100) / 100}</td>*/}
                        {/*    </tr>*/}
                        {/*}*/}
                        {/*{appCtx.gst &&*/}
                        {/*    <tr>*/}
                        {/*        <td colSpan={3} className="text-center ">CGST</td>*/}
                        {/*        <td className="text-right ">{Math.round((appCtx.billData.cgstAmount) * 100) / 100}</td>*/}
                        {/*    </tr>*/}
                        {/*}*/}
                        <tr>
                            <td colSpan={5} className="text-center">
                                <hr/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} className="text-left font-black">বাজারে কিনলে: </td>
                            <td colSpan={1} className="text-center">{Math.floor(appCtx.billData.totalBeforeDiscount)}</td>
                            <td></td>
                        </tr>
                        {/*{appCtx.discount && <tr>
                        `*/}
                        {/*    <td colSpan={3} className="text-center">Discount @ {appCtx.discount}%</td>*/}
                        {/*    <td className="text-right">{(appCtx.billData.discountAmount) * 100 / 100}</td>*/}
                        {/*</tr>}*/}
                        <tr>
                            <td colSpan={4} className="text-left font-black">সস্তা বাজারে লাগলো:</td>
                            <td colSpan={1} className="text-center">{appCtx.billData.totalWithDiscount}</td>
                        </tr>
                        <tr>
                            <td colSpan={5} className="text-center">
                                <hr/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} className="text-left font-black">আপনার সেভ হলো:</td>
                            <td colSpan={2} className="text-center">₹{Math.floor(appCtx.billData.totalBeforeDiscount) - appCtx.billData.totalWithDiscount}.00  ☺︎</td>
                        </tr>
                        <tr>
                            <td colSpan={5} className="text-center">
                                <hr/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={4}
                                className="text-sm w-full">Rs. {converter.toWords(appCtx.billData.totalWithDiscount)} only.
                            </td>
                        </tr>
                    </table>
                }

            </div>
            <br/>
            {/* <div className="text-xs">KOT No.{billNo}</div> */}
            <br/>
            <div className="flex flex-col justify-end items-center flex-1 gap-4 text-xs">
                {/* <div>FSSAI No: {CUSTOMER_FSSAI_NO}</div> */}
                {/*<div>{THANK_YOU_MSG}</div>*/}
                <div>☺︎ {THANK_YOU_MSG_BENGALI} ☺︎</div>
                {/*<div className="text-center text-xs">{FINALTANDC}</div>*/}
            </div>
        </div>
    )
})

import {useContext, useState} from "react"
import NewItemForm from "./NewItemForm";
import {Navigate} from "react-router-dom";
import {AppContext} from "../App";
import {getItemFromStorage} from "./AuthenticationComponent";
import OldItemForm from "./OldItemForm";

function Entry() {
    const appCtx = useContext(AppContext);
    const [newItem, setNewItem] = useState(false);
    const [oldItem, setOldItem] = useState(false);

    if (getItemFromStorage("userName")) {
        appCtx.setUser(getItemFromStorage("userName"));
    }

    if (!appCtx.user) {
        return <Navigate to={"/"} replace="true" />
    }
    else {
        return (
            <div className="flex flex-row">
                <div className="flex flex-1">
                    <OldItemForm/>
                </div>
                <div className={"h-full w-1 m-1 bg-gray-600"}></div>
                <div className="flex flex-1 justify-center items-center">
                    <NewItemForm/>
                </div>
            </div>
        )
    }
}

export default Entry

import {useContext, useEffect} from "react"
import {Navigate} from "react-router-dom";
import {AppContext} from "../App";
import {getItemFromStorage} from "./AuthenticationComponent";

function Home() {
    const appCtx = useContext(AppContext);

    useEffect(() => {
        if (getItemFromStorage("userName")) {
            appCtx.setUser(getItemFromStorage("userName"));
        }
    }, []);

    if (!appCtx.user) {
        return <Navigate to={"/"} replace="true" />
    }
    else {
        return (
            <div className={" h-screen w-screen font-poppins"}>
                <div className="p-4 m-4 flex justify-center text-center text-3xl h-auto flex-col gap-4 ">
                    <div className="font-bold text-6xl mt-8">Welcome to Sasta Bazaar</div>
                    <div className="font-bold text-6xl mt-8">সস্তা বাজার-এ আপনাকে স্বাগত জানাই</div>
                    {/*<div className="font-bold text-2xl italic">One stop solution for all your billing needs</div>*/}
                    <br/><br/>

                    <div className="text-red-300"><b>Billing / বিলিং</b> : For all billing details | বিলিং সংক্রান্ত কাজ-এর জন্য </div>
                    <div className="text-blue-300"><b>Add Items / আইটেম যোগ করুন</b> : Search old items or add new items | পুরানো আইটেম খুঁজুন বা নতুন আইটেম যোগ করুন</div>
                    {/*<div className="text-green-300"><b>Party Entry</b> : Add new customers</div>*/}
                    {/*<div className="text-green-300"><b>Stock</b> : Add stocks of the items</div>*/}
                    {/*<div className="text-orange-300"><b>Sales</b> : Details of the sales done</div>*/}
                </div>
            </div>
        )
    }
}

export default Home

import {MDBBtn, MDBInput} from "mdb-react-ui-kit";
import ax from "./service/axios";
import {useContext, useState} from "react";
import {AppContext} from "../App";

function NewItemForm() {
    const appCtx = useContext(AppContext);
    const [partyName, setPartyName] = useState(null)
    const [partyNumber, setPartyNumber] = useState(null)
    const [partyWANumber, setPartyWANumber] = useState(null)
    const [partyAddress, setPartyAddress] = useState(null)
    const [partyEmail, setPartyEmail] = useState(null)
    const [apiCallResponse, setApiCallresponse] = useState(null)

    const config = {
        headers: {
            customerId: "PANCHAMUKHI",
            user: appCtx.user
        }
    }

    function saveNewItemDetails() {
        ax.post("/panchamukhi/user/add", {
            partyName: partyName,
            partyNumber: partyNumber,
            partyWANumber: partyWANumber,
            partyAddress: partyAddress,
            partyEmail: partyEmail
        }, config)
            .then(e => { console.log("Saved"); setApiCallresponse("Data Saved.."); appCtx.setNewItemUploaded(!appCtx.newItemUploaded) })
            .catch(e => { console.log("Err"); })
    }
    return (
        <div className="p-4 flex flex-col gap-2">
            <b>Party Name:</b> <MDBInput label='Party Name*' id='typeText' type='text'
                                        wrapperStyle={{ color: 'red', background: '#99FFCC44' }}
                                         labelStyle={{ color: 'red' }} onChange={e => setPartyName(e.target.value)} />
            <b>Party Number:</b>
            <MDBInput label='Party Number*' id='typeNumber' type='number' wrapperStyle={{ background: '#99FFCC44' }}
                      labelStyle={{ color: 'red' }} onChange={e => setPartyNumber(e.target.value)} />
            <b>Party WA Number:</b>
            <MDBInput label='Party WA Number*' id='typeNumber' type='number' wrapperStyle={{ background: '#99FFCC44' }}
                      labelStyle={{ color: 'red' }} onChange={e => setPartyWANumber(e.target.value)} />
            <b>Site Address:</b>
            <MDBInput label='Site Address*' id='typeText' type='text' wrapperStyle={{ background: '#99FFCC44' }}
                      labelStyle={{ color: 'red' }} onChange={e => setPartyAddress(e.target.value)} />
            <b>Party Email:</b>
            <MDBInput label='Party Email*' id='typeText' type='text' wrapperStyle={{ background: '#99FFCC44' }}
                      labelStyle={{ color: 'red' }} onChange={e => setPartyEmail(e.target.value)} />
            <div className="flex flex-row justify-center items-center">
                <MDBBtn size='lg' className="me-2" color="warning" onClick={e => { saveNewItemDetails() }}>SAVE</MDBBtn>
                <MDBBtn size='lg' className="me-2" color="warning" onClick={e => { }}>RESET</MDBBtn>
            </div>
            {apiCallResponse}
        </div>

    )
}

export default NewItemForm;

import {MDBBtn} from "mdb-react-ui-kit";
import {useContext, useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import {AppContext} from "../App";
import {getItemFromStorage} from "./AuthenticationComponent";
import GeneralUserPage from "./GeneralUserPage";
import LoginForm from "./LoginForm";

function GeneralUserHomePage() {
    const appCtx = useContext(AppContext);
    const [generalUser, setGeneralUser] = useState(true);
    const [empLoginBtnClicked, setEmpLoginBtnClicked] = useState(false);

    useEffect(() => {
        if (getItemFromStorage("userName")) {
            appCtx.setUser(getItemFromStorage("userName"));
        }
        if (getItemFromStorage("customerId")) {
            appCtx.setCustomerId(getItemFromStorage("customerId"));
        }
    }, []);


    if (appCtx.user) {
        return <Navigate to={"/home"} replace="true" />
    } else {
        return (
            <div className="h-full bg-gray-200">
                {!empLoginBtnClicked &&
                <div className={"flex flex-row content-end items-end justify-end w-screen gap-2"}>
                    <MDBBtn color="danger" className="mr-2 mt-2" type="submit"
                            onClick={() => {setGeneralUser(false); setEmpLoginBtnClicked(true)}}>Employee Login</MDBBtn>

                </div>
                }
                {generalUser && <GeneralUserPage/>}
                {!generalUser && <LoginForm/>}
            </div>
        )
    }
}

export default GeneralUserHomePage;

import React, {useContext, useEffect, useState} from 'react';
import {MDBBtn, MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead, MDBTextArea} from "mdb-react-ui-kit";
import ax from "./service/axios";
import {AppContext} from "../App";
import {Button, styled} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function BuyingDetails() {
    const appCtx = useContext(AppContext);
    const [itemDetails, setItemDetails] = useState({
        itemName: '',
        itemCategory: '',
        totalPrice: '',
        quantity: '',
        extraCharges: '',
        purchaseDate: '',
        extraInformation: ''
    });

    const [buyingDetails, setBuyingDetails] = useState(null)
    const [pageRefreshToogle, setPageRefreshToogle] = useState(false)
    const [isAddItemBtnClicked, setIsAddItemBtnClicked] = useState(false)
    const [file, setFile] = useState();
    const [uploadProgress, setUploadProgress] = useState(0);

    // const [storage, setStorage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setItemDetails({
            ...itemDetails,
            [name]: value
        });
    };

    function handleFileChange(e) {
        setFile(e.target.files[0]);
    }

    const config = {
        headers: {
            customerId: "SASTA_BAZAAR",
            user: appCtx.user,
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function(progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    // Api to call backend for buying details
    useEffect(() => {
        fetchBuyingDetails();
    }, []);

    function fetchBuyingDetails() {
        const res = ax.get("/sastabazaar/items", config);
        res
            .then(e => { setBuyingDetails(e.data); })
            .catch(e => { console.log("Err: ", e); })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsAddItemBtnClicked(true);
        try {
            const formData = new FormData();
            formData.append('file', file);
            Object.keys(itemDetails).forEach(key => {
                formData.append(key, itemDetails[key]);
            });
            await ax.post('/sastabazaar/items/buy', formData, config);
            alert('Item added successfully');
            setIsAddItemBtnClicked(false);
        } catch (error) {
            console.error('Error adding item:', error);
        }
        setPageRefreshToogle(pageRefreshToogle => !pageRefreshToogle);
    };

    function getTotalSpend() {
        let totalSumOfItemsBought = 0
        buyingDetails.map((item) => {
            totalSumOfItemsBought += (item["totalPrice"]+item["extraCharges"])
        })
        return Math.ceil(totalSumOfItemsBought);
    }

    const downloadFile = async (fileName) => {
        try {
            const response = await ax.get(`/sastabazaar/download/${fileName}`, {
                responseType: 'blob',
                headers: {
                    customerId: "SASTA_BAZAAR",
                    user: appCtx.user,
                }
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div className={"flex flex-row"}>
            <div className={"border-2 border-amber-900 ml-1"}>
                <div align={"center"} className={"bg-yellow-200 text-red-800 border-2 border-amber-900"}>Add New Buying Details / নতুন কেনার হিসাব যোগ</div>
                <form onSubmit={handleSubmit} className={"flex flex-col justify-center items-center gap-2 border-2 border-amber-900"}>
                    <div className={"shadow rounded "}>
                        <MDBTable>
                            <MDBTableBody>
                                <tr className='table-primary'>
                                    <td>Add Item Name / আইটেম নাম:</td>
                                    <td className={"bg-white"}>
                                        <MDBInput
                                            type="text"
                                            name="itemName"
                                            value={itemDetails.productName}
                                            onChange={handleChange}
                                            required
                                            label={"Item Name"}
                                        /></td>
                                </tr>
                                <tr className='table-primary'>
                                    <td>Add Item Category / ক্যাটাগরি:</td>
                                    <td className={"bg-white"} align={"center"}>
                                        <select className={"border p-2 w-full text-center"} required="true"
                                                defaultValue={"0"}
                                                name={"itemCategory"} onChange={handleChange}>
                                            <option value="0">Select Category</option>
                                            <option value="Grocery">Grocery</option>
                                            <option value="Electronics">Electronics</option>
                                            <option value="Clothing">Clothing</option>
                                            <option value="Footwear">Footwear</option>
                                            <option value="Accessories">Accessories</option>
                                            <option value="Kitchen">Kitchen</option>
                                            <option value="Furniture">Furniture</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr className='table-primary'>
                                    <td>Add Total Price / টোটাল খরচ:</td>
                                    <td className={"bg-white"}>
                                        <MDBInput
                                            type="number"
                                            name="totalPrice"
                                            value={itemDetails.totalPrice}
                                            onChange={handleChange}
                                            required
                                            label={"Total Price"}
                                        />
                                    </td>
                                </tr>
                                <tr className='table-primary'>
                                    <td>Add Quantity / কতগুলো:</td>
                                    <td className={"bg-white"}>
                                        <MDBInput
                                            type="number"
                                            name="quantity"
                                            value={itemDetails.quantity}
                                            onChange={handleChange}
                                            label={"Quantity"}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr className='table-primary'>
                                    <td>Add Extra charges(eg courier) / এক্সট্রা খরচ:</td>
                                    <td className={"bg-white"}>
                                        <MDBInput
                                            type="number"
                                            name="extraCharges"
                                            value={itemDetails.extraCharges}
                                            onChange={handleChange}
                                            label={"Extra Charges(if any)"}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr className='table-primary'>
                                    <td>Add Purchase Date / কেনার ডেট:</td>
                                    <td className={"bg-white"}>
                                        <MDBInput
                                            type="date"
                                            name="purchaseDate"
                                            value={itemDetails.purchaseDate}
                                            onChange={handleChange}
                                            label={"Purchase Date"}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr className='table-primary'>
                                    <td>Add More Details(eg any note/comments) / এক্সট্রা কিছু ডিটেল:</td>
                                    <td className={"bg-white"}>
                                        <MDBTextArea
                                            type="text"
                                            name="extraInformation"
                                            value={itemDetails.extraInformation}
                                            onChange={handleChange}
                                            label={"Comments"}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr className='table-primary'>
                                    <td>Attach Bill Pic / বিল-এর কোনো ছবি:</td>
                                    <td className={"bg-white"}>
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Upload file
                                            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                                        </Button>
                                        {uploadProgress > 0 && <p>Upload Progress: {uploadProgress}%</p>}
                                    </td>
                                </tr>
                                <br/>
                                <tr>
                                    <td colSpan={"2"}>
                                        <div className={"flex flex-row justify-center items-center gap-2"}>
                                            <MDBBtn type="submit" color={"success"} disabled={isAddItemBtnClicked}>Add
                                                Item</MDBBtn>
                                            <MDBBtn type="submit" color={"warning"}>Reset</MDBBtn>
                                        </div>
                                    </td>
                                </tr>
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </form>
            </div>
            <div className={"h-full w-1 m-1 bg-gray-600"}></div>
            <div className={"mr-1 flex flex-col flex-1 "}>
                <div align={"center"} className={"bg-yellow-200 text-red-800 border-2 border-amber-900"}>Old Buying
                    History / আগের কেনাকাটার হিসাব
                </div>
                <MDBTable bordered borderColor="primary">
                    <MDBTableHead>
                        <tr align={"center"} className={"bg-red-100 text-xs"}>
                            <th className={"text-black"}>Item Name / আইটেম নাম</th>
                            <th className={"text-black"}>Date / ডেট</th>
                            <th className={"text-black"}>Qty / কতগুলো</th>
                            <th className={"text-black"}>Category / ক্যাটাগরি</th>
                            <th className={"text-black"}>Unit Price / একটা আইটেম দাম</th>
                            <th className={"text-black"}>Extra Charges / এক্সট্রা খরচ</th>
                            <th className={"text-black"}>Total Price / টোটাল খরচ</th>
                            <th className={"text-black"}>Bill Pic / বিল-এর ছবি</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {buyingDetails && buyingDetails.map((item) => {
                            return (
                                <tr align={"center"} className={"bg-yellow-50 text-xs"}>
                                    <td style={{width: "12rem"}}>{item["itemName"]}</td>
                                    <td style={{width: "8rem"}}>{item["purchaseDate"]}</td>
                                    <td>{item["quantity"]}</td>
                                    <td>{item["itemCategory"]}</td>
                                    <td>{item["unitPrice"]}</td>
                                    <td>{item["extraCharges"]}</td>
                                    <td>{item["totalPrice"]}/-</td>
                                    <td> {item["fileName"]?
                                        <MDBBtn floating color={"danger"}
                                                onClick={e => downloadFile(item["fileName"])}>
                                            <MDBIcon fas icon="file-invoice" />
                                        </MDBBtn>
                                        : <span>No file</span>
                                    }
                                    </td>
                                </tr>
                            )
                        })}
                        <tr align={"center"} className={" bg-green-200 font-bold"}>
                            <td colSpan="8">Total Spend (Till Date, Including Extra Charges) | টোটাল কেনাকাটা
                                &nbsp;=>&nbsp; {buyingDetails && getTotalSpend()}/-
                            </td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </div>
        </div>
    )
}
import React from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import img1 from "../shop_pics/1_resized.jpg"
import img2 from "../shop_pics/2_resized.jpg"
import img3 from "../shop_pics/3_resized.jpg"
import img4 from "../shop_pics/4_resized.jpg"
import img5 from "../shop_pics/5_resized.jpg"
import img6 from "../shop_pics/6_resized.png"
import img7 from "../shop_pics/7_resized.png"

export default function CarouselSlider() {
    const carouselSlide = "w-full d-block";
    return (
        <div className={"ml-24 mr-24 h-28rem"}>
        <MDBCarousel showIndicators showControls fade>
            <MDBCarouselItem
                className={carouselSlide}
                itemId={1}
                src={img1}
                alt="..."
            >
                <h5>৫/- টাকার চিপস ৩/- টাকায়</h5>
                <p>যে চিপস আপনি বাজার-এ ৫/- টাকায় কিনছেন আমাদের দোকানে তা মাত্র ৩/- টাকায়</p>
            </MDBCarouselItem>

            <MDBCarouselItem
                className={carouselSlide}
                itemId={2}
                src={img2}
                alt="..."
            >
                <h5>হোয়াটস্যাপ</h5>
                <p>পাবেন হোয়াটস্যাপ এ সব মাল দেখতে</p>
            </MDBCarouselItem>

            <MDBCarouselItem
                className={carouselSlide}
                itemId={3}
                src={img3}
                alt="..."
            >
                <h5>ডেলিভারি</h5>
                <p>ফ্রি ডেলিভারি</p>
            </MDBCarouselItem>
            <MDBCarouselItem
                className={carouselSlide}
                itemId={4}
                src={img4}
                alt="..."
            >
                <h5>পাবেন ফ্যাশন করার সব সামগ্রী</h5>
                <p>পাবেন ব্র্যান্ডেড কুর্তি, জিন্স, চশমা, সান্ডালস, নেইল পলিশ, লিপি গ্লস আরো কত কি</p>
            </MDBCarouselItem>
            <MDBCarouselItem
                className={carouselSlide}
                itemId={5}
                src={img5}
                alt="..."
            >
                <h5>চলে আসুন</h5>
                <p>আছে দারুন দারুন অফার, সব মাল এ প্রচুর ডিসকাউন্ট</p>
            </MDBCarouselItem>
            <MDBCarouselItem
                className={carouselSlide}
                itemId={6}
                src={img6}
                alt="..."
            >
                <h5>১০০০ টাকার বাজার ৬০০ টাকায়</h5>
                <p>হরেক মাল এর উপর পাবেন ৪০% এর ও বেশি ডিসকাউন্ট, মানে ১০০ টাকার মাল ৪০ টাকায়   </p>
            </MDBCarouselItem>
            <MDBCarouselItem
                className={carouselSlide}
                itemId={7}
                src={img7}
                alt="..."
            >
                <h5>যা চাইছেন তাই-ই পাবেন</h5>
                <p>পাবেন মুদিখানা-র সামগ্রী, কসমেটিক, জামা কাপড়, ফ্যাশন, বেবি প্রোডাক্টস আরো অনেক কিছু</p>
            </MDBCarouselItem>
        </MDBCarousel>
        </div>
    );
}

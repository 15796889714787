import {MDBInput, MDBSpinner, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import {useContext, useEffect, useState} from "react";
import ax from "./service/axios";
import {Navigate} from "react-router-dom";
import {AppContext} from "../App";
import {getItemFromStorage} from "./AuthenticationComponent";

function Sales() {
    const appCtx = useContext(AppContext);
    const [saleResponse, setSaleResponse] = useState(null);
    const [isBtnActiveYesterday, setIsBtnActiveYesterday] = useState(true);
    const [isBtnActiveToday, setIsBtnActiveToday] = useState(true);

    useEffect(() => {
        if (getItemFromStorage("userName")) {
            appCtx.setUser(getItemFromStorage("userName"));
        }
        if (getItemFromStorage("customerId")) {
            appCtx.setCustomerId(getItemFromStorage("customerId"));
        }
    }, []);

    const config = {
        headers: {
            customerId: "SASTA_BAZAAR",
            user: appCtx.user
        }
    }

    function fetchSalesDetails(val) {
        ax.post("/sales/details/list", {
            "selectedValue": val,
            "customerId": "SASTA_BAZAAR"
        }, config)
            .then(res => {
                console.log(res.data);
                setIsBtnActiveYesterday(true);
                setIsBtnActiveToday(true);
                setSaleResponse(res.data);
            })
            .catch(err => console.log(err));
    }

    if (!appCtx.user) {
        return <Navigate to={"/"} replace="true" />
    }
    else {
        return (
            <div className="pl-6 pr-6 flex flex-col items-center justify-center p-4 gap-4 h-screen justify-content-start">
                <div className="flex flex-row">
                    <div className="flex justify-center items-center m-1 text-lg py-3 px-4 rounded-full text-red bg-pink-100 shadow border-2 border-black">
                        <button className="font-normal leading-none max-w-full flex-initial" value={"yesterday"} disabled={!isBtnActiveYesterday} onClick={e => { fetchSalesDetails(e.target.value); setIsBtnActiveYesterday(false); }}>Yesterday / গতকাল</button>
                    </div>
                    <div className="flex justify-center items-center m-1 text-lg py-3 px-4 rounded-full text-red bg-pink-100 shadow border-2 border-black">
                        <button className="font-normal leading-none max-w-full flex-initial" value={"today"} disabled={!isBtnActiveToday} onClick={e => { fetchSalesDetails(e.target.value); setIsBtnActiveToday(false); }}>Today / আজকের</button>
                    </div>
                </div>
                {!isBtnActiveYesterday &&
                    <div className={"p-2 flex flex-col justify-center items-center content-center"}>
                        <MDBSpinner role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                        <div>Loading.. Wait..</div>
                    </div>
                }
                {!isBtnActiveToday &&
                    <div className={"p-2 flex flex-col justify-center items-center content-center"}>
                        <MDBSpinner role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                        <div>Loading.. Wait..</div>
                    </div>
                }
                <div>
                    {saleResponse !== null &&
                        <MDBTable bordered borderColor="primary" className="shadow bg-green-50 align-middle">
                            <MDBTableBody>
                                <tr>
                                    <td>Start Time</td>
                                    <td><MDBInput label="From" id='formControlDisabled' type='text' value={saleResponse["startDate"]} disabled /></td>
                                </tr>
                                <tr>
                                    <td>End Time</td>
                                    <td><MDBInput label="To" id='formControlDisabled' type='text' value={saleResponse["endDate"]} disabled /></td>
                                </tr>

                                <tr>
                                    <td>Total Orders</td>
                                    <td><MDBInput label="Total Orders" id='formControlDisabled' type='text' value={Math.floor(saleResponse["totalOrdersGenerated"])} disabled /></td>
                                </tr>
                                <tr>
                                    <td>Total Amount</td>
                                    <td><MDBInput label="Total Sales" id='formControlDisabled' type='text' value={Math.floor(saleResponse["totalSales"])} disabled /></td>
                                </tr>

                            </MDBTableBody>
                        </MDBTable>
                    }
                        <br/>
                    {saleResponse !== null &&
                        <MDBTable align={"middle"}>
                            <MDBTableHead>
                                <tr className={"bg-amber-50 border-2 border-black text-yellow-900 text-xs"} align={"center"}>
                                    <th scope='col' className={"bg-blue-50 border border-black"}>Bill Id</th>
                                    <th scope='col' className={"bg-blue-50 border border-black"}>Bill Date</th>
                                    <th scope='col' className={"bg-blue-50 border border-black"}>Bill Amount</th>
                                    <th scope='col' className={"bg-blue-50 border border-black"}>Items Sold</th>
                                    <th scope='col' className={"bg-blue-50 border border-black"}>Customer Mobile No</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {saleResponse["sastaBazaarBillDetailsList"].map((item, index) => {
                                    return (
                                        <tr key={index} className={"bg-blue-50 border-2 border-black text-xs"}>
                                            <td align={"center"} className={"bg-blue-50 border border-black"}>{item["billId"]}</td>
                                            <td align={"center"} className={"bg-blue-50 border border-black"}>{item["createdAt"]}</td>
                                            <td align={"center"} className={"bg-blue-50 border border-black"}>{item["finalAmount"]}</td>
                                            <td align={"center"} className={"bg-blue-50 border border-black"} width={"396px"}>{item["itemsSold"]}</td>
                                            <td align={"center"} className={"bg-blue-50 border border-black"}>{item["customerMobileNumber"]}</td>
                                        </tr>
                                    )
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    }
                </div>
            </div >
        )
    }
}
export default Sales;

import {Route, Routes} from "react-router-dom";
import Entry from "./Entry";
import Home from "./Home";
import Sales from "./Sales";
import GeneralUserHomePage from "./GeneralUserHomePage";
import Billing from "./Billing";
import Party from "./Party";
import BuyingDetails from "./BuyingDetails";
import SendSMSToCustomer from "./SendSMSToCustomer";

function RoutingSetup() {
    return (
        <Routes>
            <Route exact path={"/home"} element={<Home />} />
            <Route exact path={"/billing"} element={<Billing />} />
            <Route exact path={"/entry"} element={<Entry />} />
            <Route exact path={"/items-buy"} element={<BuyingDetails />} />
            <Route exact path={"/send-sms"} element={<SendSMSToCustomer />} />
            <Route exact path={"/party"} element={<Party />} />
            {/*<Route exact path={"/stock"} element={<Stock />} />*/}
            <Route exact path={"/sales"} element={<Sales />} />
            {/*<Route exact path={"/wastage"} element={<Wastage />} />*/}
            <Route exact path={"/"} element={<GeneralUserHomePage />} />
        </Routes>
    )
}

export default RoutingSetup

import logo from "../shop_pics/supermarket.png";
import {CUSTOMER_NAME} from "./constants/DynamicConstants";

export default function LogoAndCustomerName() {
    return (
        <div className={"flex flex-row justify-center content-center font-poppins items-center gap-2 border-2 border-amber-400 rounded p-1"}>
            <img src={logo} alt="logo" className="h-16 w-auto pl-2"/>
            <div className={"p-2"}> {CUSTOMER_NAME}</div>
        </div>
    )
}
import {MDBBtn, MDBInput, MDBTable, MDBTableBody, MDBTextArea} from "mdb-react-ui-kit";
import {useContext, useState} from "react";
import ax from "./service/axios";
import {AppContext} from "../App";

export default function SendSMSToCustomer() {
    const appCtx = useContext(AppContext);

    const [itemDetails, setItemDetails] = useState({
        customerMobileNumber: '',
        messageToSend: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setItemDetails({
            ...itemDetails,
            [name]: value
        });
    };

    const config = {
        headers: {
            customerId: "SASTA_BAZAAR",
            user: appCtx.user
        }
    }

    const submitSMSData = async (e) => {
        e.preventDefault();
        try {
            const response = await ax.post('/sastabazaar/sms/send', itemDetails, config);
            console.log('SMS Sent successfully:', response.data);
        } catch (error) {
            console.error('Error sending sms:', error);
        }
    };

    return (
        <div className="flex justify-center content-center items-center w-full pt-16">
            <form onSubmit={submitSMSData}>
                <MDBTable bordered className="align-middle shadow" style={{width: "64rem", height: "32rem"}}>
                    <MDBTableBody>
                        <tr className="bg-yellow-100">
                            <td align={"center"} className={"bg-yellow-400 font-bold"}>Message To All Customers / সব কাস্টমার কে মেসেজ পাঠান</td>
                            <td><MDBTextArea style={{height: "32rem", background: "aliceblue"}} label="Enter Message / মেসেজ টাইপ করুন" name={"messageToSend"} onChange={handleChange} id="formUnInput" type="text" required/></td>
                        </tr>
                        <tr align="center" className="bg-red-50">
                            <td colSpan={2}>
                                <MDBBtn color="success" className="me-1" type="submit" disabled>SEND</MDBBtn>
                                <MDBBtn className="me-1" type="reset" color="warning" disabled>RESET</MDBBtn>
                            </td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </form>
        </div>
    )
}
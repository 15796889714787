import { useContext, useEffect, useState } from "react";
import ax from "./service/axios";
import {MDBBtn, MDBInput, MDBSpinner, MDBTable, MDBTableBody} from "mdb-react-ui-kit";
import { AppContext } from "../App";
import {Form} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";

function OldItemForm() {
    const appCtx = useContext(AppContext);
    const [data, setData] = useState([]);
    const [itemsLoaded, setItemsLoaded] = useState(false);
    const [singleSelection, setSingleSelection] = useState([]);
    const [unitPrice, setUnitPrice] = useState(null)
    const [stock, setStock] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null);
    const [unitType, setUnitType] = useState(null);
    const [stockUpdate, setStockUpdate] = useState(false);
    const [apiCallResponse, setApiCallresponse] = useState(null)
    useEffect(() => {
        async function loadItems() {
            try {
                const response = await ax.get('/products/fetch/stocks/all', config);
                setData(response.data);
                setItemsLoaded(true);
            } catch (error) {
                console.log('Error in fetching data from backend', error);
            }
        }
        loadItems();
    }, [stockUpdate]);

    const config = {
        headers: {
            customerId: "SASTA_BAZAAR",
            user: appCtx.user
        }
    }
    function updateItemDetails() {
        ax.post("/stock/update", {
            productId: selectedItem.id,
            stock: stock,
            unitType: unitType,
            updatedBy: appCtx.user
        }, config)
            .then(e => { console.log("Product Data Updated"); setApiCallresponse("Product Data Updated"); setSelectedItem(null); setStockUpdate(!stockUpdate); })
            .catch(e => { console.log("Err"); setApiCallresponse("Some technical Error. Try again."); })
    }

    function clearStock() {
        ax.post("/stock/clear", {
            productId: selectedItem.id
        }, config)
            .then(e => { setApiCallresponse("Pending Stock Cleared"); setSelectedItem(null); setStockUpdate(!stockUpdate); })
            .catch(e => { setApiCallresponse("Some technical Error. Try again."); })
    }

    function deleteItem() {
        ax.post("/products/delete", {
            id: selectedItem.id,
            productName: selectedItem.productName,
            modifiedBy: appCtx.user,
        }, config)
            .then(e => { console.log("Product Data Deleted"); setApiCallresponse("Product Data Deleted"); setSelectedItem(null); setStockUpdate(!stockUpdate); })
            .catch(e => { console.log("Err"); setApiCallresponse("Some technical Error / আপনি প্রোডাক্ট ডিলিট করতে পারবেন না"); })
    }

    const renderMenuItemChildren = (option, props) => (
        <div>
            <span>{option.productName} - [MRP {option.unitPrice}/-]</span>
        </div>
    );

    return (
        <div className="pl-4 flex flex-col flex-1">
            <div className="w-full border-2 shadow rounded-lg p-2 bg-gray-400">
                <Form.Group>
                    <Form.Label>Product Details / প্রোডাক্ট-এর ডিটেলস দেখুন</Form.Label>
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="productName"
                        onChange={(e) => {
                            setSelectedItem(e[0]);
                            setApiCallresponse(null);
                        }}
                        options={data}
                        placeholder=" Type Here"
                        inputProps={{className: "bg-white border border-red-400 rounded w-full h-8"}}
                        selected={singleSelection}
                        renderMenuItemChildren={renderMenuItemChildren}
                        disabled={!itemsLoaded}
                    />
                </Form.Group>
                {!itemsLoaded &&
                    <div className={"p-8 flex flex-col justify-center items-center content-center"}>
                        <MDBSpinner role='status'>
                            <span className='visually-hidden'>Loading...</span>
                        </MDBSpinner>
                        <div>Loading Products..</div>
                    </div>
                }
            </div>
            <br/><br/>
            <div className={" border-gray-700 shadow-lg rounded"}>
                {selectedItem &&
                    <MDBTable bordered borderColor="primary">
                        <MDBTableBody>
                            <tr className="border-2 border-gray-600">
                                <td>Item Name / আইটেম-এর নাম</td>
                                <td><div className={"flex flex-wrap"}>{selectedItem["productName"]}</div>
                                    </td>
                            </tr>
                            <tr className="border-2 border-gray-600">
                                <td>Item Created On / আইটেম এন্ট্রি-র সময়</td>
                                <td><MDBInput label="Created On" id='formControlDisabled' type='text'
                                              value={selectedItem["createdAt"]} disabled/></td>
                            </tr>
                            <tr className="border-2 border-gray-600">
                                <td>MRP / অরিজিনাল দাম</td>
                                <td><MDBInput label="MRP" id='formControlunitPrice' type='text'
                                              value={selectedItem["unitPrice"]}
                                              wrapperStyle={{background: "white"}}
                                              disabled/></td>
                            </tr>
                            <tr className="border-2 border-gray-600">
                                <td>Discounted MRP / ডিসকাউন্ট দাম</td>
                                <td><MDBInput label="Discounted Price" id='formControlunitPrice' type='text'
                                              value={selectedItem["discountedUnitPrice"]}
                                              wrapperStyle={{background: "white"}}
                                              disabled/></td>
                            </tr>
                            <tr align="center" className="border-2 border-gray-600">
                                <td colSpan={2}>
                                    <MDBBtn size='lg' className="me-2" color="info" onClick={e => {
                                        //updateItemDetails()
                                    }}>UPDATE</MDBBtn>
                                    <MDBBtn size='lg' className="me-2" color="danger" onClick={e => {
                                        deleteItem()
                                    }}>DELETE</MDBBtn>
                                </td>
                            </tr>
                            {apiCallResponse &&
                            <tr align="center" className="border-2 font-bold text-xs bg-amber-200 shadow rounded p-1">
                                <td colSpan={2} >
                                    {apiCallResponse}
                                </td>
                            </tr>
                            }
                        </MDBTableBody>
                    </MDBTable>
                }
            </div>
        </div>
    )
}

export default OldItemForm;

export const CUSTOMER_NAME = "সস্তা বাজার | Sasta Bazaar";
export const CUSTOMER_NAME_PRINT = "| সস্তা বাজার শপিং মল |";
export const CUSTOMER_TAG_LINE = "The Food Park";
export const FOOD_TYPE = "Veg + Non-Veg";
export const CUSTOMER_LOCATION1 = "Ailakundi, Bankura";
export const CUSTOMER_LOCATION2 = "Near Indian Gas";
export const SECTION_SEPARATOR = "---------- Tax Invoice ----------";
export const NO_CONTENT_SECTION_SEPARATOR = "----------------------";
export const TODAYS_DATE = new Date().toLocaleDateString();
export const TODAYS_TIME = new Date().toLocaleTimeString();
export const CUSTOMER_GST_NO = "P233QRXXXXXX67367";
export const CUSTOMER_FSSAI_NO = "P233QRXXXXXX67367";
export const THANK_YOU_MSG = "Thank You. Visit Again.";
export const THANK_YOU_MSG_BENGALI = "ধন্যবাদ, আবার আসবেন";
export const FINALTANDC = "PLEASE CHECK THE BILL BEFORE MAKING THE PAYMENT. NO COMPLAINTS WILL BE TAKEN LATER.";
export const BILL_PAGE_WIDTH_MM = 58;
export const BILL_PAGE_HEIGHT_MM = 210;

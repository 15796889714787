import {Link, Navigate} from "react-router-dom";
import {useContext, useState} from "react";
import {AppContext} from "../App";
import ReceiptIcon from '@mui/icons-material/Receipt';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ContactMailIcon from '@mui/icons-material/ContactMail';

function LinkSetup() {
    const [selectedValue, setSelectedValue] = useState("");
    const appCtx = useContext(AppContext);

    if (!appCtx.user) {
        return <Navigate to={"/"} replace="true" />
    }
    else {
        return (
            <div className={"font-poppins"}>
                <div>
                    <ul className="flex justify-evenly p-2 border-2 border-danger">
                        <li>
                            <div className={"p-1 rounded"}>
                                <Link to="/billing"
                                      className={selectedValue === "Billing" ? "p-2 text-center border-b-2 text-gray-800 font-bold rounded bg-orange-200" : "p-2 text-center hover:text-yellow-900 hover:bg-yellow-200 hover:border hover:border-black hover:rounded"}
                                      onClick={() => setSelectedValue("Billing")}
                                      exact><ReceiptIcon/> Billing / বিলিং</Link>
                            </div>
                        </li>
                        <li>
                            <div className={"p-1 rounded"}>
                                <Link to="/entry"
                                      className={selectedValue === "Entry" ? "p-2 text-center border-b-2 text-gray-800 font-bold rounded bg-orange-200" : "p-2 text-center hover:text-yellow-900 hover:bg-yellow-200 hover:border hover:border-black hover:rounded"}
                                      onClick={() => setSelectedValue("Entry")}
                                ><InventoryIcon/> Add New Product / নতুন প্রোডাক্ট যোগ</Link>
                            </div>
                        </li>

                        <li>
                            <div className={"p-1 rounded"}>
                                <Link to="/items-buy"
                                      className={selectedValue === "ItemsBuy" ? "p-2 text-center border-b-2 text-gray-800 font-bold rounded bg-orange-200" : "p-2 text-center hover:text-yellow-900 hover:bg-yellow-200 hover:border hover:border-black hover:rounded"}
                                      onClick={() => setSelectedValue("ItemsBuy")}
                                ><AccountBalanceWalletIcon/> Add Item Bought / মাল কেনার হিসাব যোগ</Link>
                            </div>
                        </li>
                        <li>
                            <div className={"p-1 rounded"}>
                                <Link to="/sales"
                                      className={selectedValue === "Dashboard" ? "p-2 text-center border-b-2 text-gray-800 font-bold rounded bg-orange-200" : "p-2 text-center hover:text-yellow-900 hover:bg-yellow-200 hover:border hover:border-black hover:rounded"}
                                      onClick={() => setSelectedValue("Dashboard")}
                                ><MonetizationOnIcon/> Sales / বিক্রির হিসাব</Link>
                            </div>
                        </li>
                        <li>
                            <div className={"p-1 rounded"}>
                                <Link to="/send-sms"
                                      className={selectedValue === "SendSMS" ? "p-2 text-center border-b-2 text-gray-800 font-bold rounded bg-orange-200" : "p-2 text-center hover:text-yellow-900 hover:bg-yellow-200 hover:border hover:border-black hover:rounded"}
                                      onClick={() => setSelectedValue("SendSMS")}
                                ><ContactMailIcon/> Send Promotion SMS / প্রমোশন মেসেজ পাঠান</Link>
                            </div>
                        </li>
                        {/*<li>*/}
                        {/*    <Link to="/wastage"*/}
                        {/*        className={selectedValue === "Wastage" ? "p-2 text-center border-b-2 text-green-400 bg-blue-gray-900 rounded font-bold text-xl" : "p-2 text-center hover:text-green-400 text-2xl"}*/}
                        {/*        onClick={() => setSelectedValue("Wastage")}*/}
                        {/*    >Wastage</Link>*/}
                        {/*</li>*/}
                        {/* <li>
                        <Link to="/search"
                            className={selectedValue === "Search" ? "p-2 text-center border-b-2 text-orange-400 bg-blue-gray-900 rounded font-bold text-xl" : "p-2 text-center hover:text-orange-400 text-2xl"}
                            onClick={() => setSelectedValue("Search")}
                        >Search</Link>
                    </li> */}
                    </ul>
                </div>
            </div>
        )
    }
}

export default LinkSetup
